@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900");
/**
*
* Leaft map
*
 */
/* device  */
@import url("//fonts.googleapis.com/css?family=Economica");
.col-centered {
  float: none;
  margin: 0 auto;
}

.container-fluid {
  padding: 0;
}

.full-height {
  height: 100vh;
}

.scroll {
  overflow-y: auto;
}

.full-height-fixed {
  height: calc(100vh - 56px);
  margin-top: 56px;
  overflow: hidden;
  margin-right: 0;
  margin-left: 0;
}

.full-height-fixed > * {
  padding: 0;
}

.full-height-fixed .content {
  overflow-x: hidden;
  overflow-y: auto;
}

.clickable {
  cursor: pointer;
}

.text-white a {
  color: rgba(255, 255, 255, 0.76);
}

.bg-dark {
  background-color: #292b2c !important;
}

span.color-preview {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}

input[type=color] {
  padding: 0;
  height: 35px;
}

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

/**
* Scrollbar
 */
*::-webkit-scrollbar {
  width: .5em;
}

*::-webkit-scrollbar-thumb {
  background-color: #292b2c;
  border-radius: 5px;
  outline: 1px solid slategrey;
}

body {
  background-color: #eee;
  font-size: 14px;
}

button,
a {
  cursor: pointer;
  border-radius: 0 !important;
}

button:active, button:focus, button:hover,
a:active,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
}

.list-group-item.active a {
  color: rgba(255, 255, 255, 0.76);
}

textarea,
input {
  border-radius: 0 !important;
}

textarea:active, textarea:focus, textarea:hover,
input:active,
input:focus,
input:hover {
  outline: none;
}

.card,
.input-group-addon {
  border-radius: 0;
}

.input-group-addon {
  background-color: #0275d8;
  color: rgba(255, 255, 255, 0.76);
}

.has-error {
  color: #d9534f;
}

.has-error .input-group-addon {
  background-color: #d9534f;
  color: rgba(255, 255, 255, 0.76);
}

.has-error input {
  border-color: #d9534f;
}

.has-error input:focus {
  border-color: #d9534f;
}

.has-error textarea {
  border-color: #d9534f;
}

.has-error textarea:focus {
  border-color: #d9534f;
}

.select2-container {
  margin: 0;
  position: relative;
  display: block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  box-sizing: border-box;
}

.select2-drop {
  box-sizing: border-box;
}

.select2-search {
  box-sizing: border-box;
}

.select2-search input {
  box-sizing: border-box;
}

.select2-container .select2-choice {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-weight: normal;
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 10px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  background: url("app/assets/images/select2.png") right top no-repeat;
  cursor: pointer;
  outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-active {
  border-top: none;
}

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
}

.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%;
  background: url("app/assets/images/select2.png") no-repeat 0 1px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}

.select2-search input {
  width: 100% !important;
  height: auto !important;
  min-width: 100%;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  background: #fff url("app/assets/images/select2.png") no-repeat 100% -22px;
  background: url("app/assets/images/select2.png") no-repeat 100% -22px, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
  width: 100% !important;
}

.select2-search input.select2-active {
  background: #fff url("app/assets/images/select2-spinner.gif") no-repeat 100%;
  background: url("app/assets/images/select2-spinner.gif") no-repeat 100%, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-container-active .select2-choice, .select2-container-active .select2-choices {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  box-shadow: 0 1px 0 #fff inset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-dropdown-open.select2-drop-above .select2-choice, .select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #0275d8;
  border-top-color: transparent;
  background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #eee 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  -webkit-filter: none;
          filter: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}

/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label {
  padding-left: 20px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 40px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 60px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 80px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 100px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 110px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 120px;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  font-weight: normal;
  font-size: 14px;
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results .select2-highlighted {
  background: #0275d8;
  color: #fff;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results, .select2-results .select2-searching, .select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url("app/assets/images/select2-spinner.gif") no-repeat 100%;
}

/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}

/* multiselect */
.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #ccc;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
  background-image: linear-gradient(to bottom, #eee 1%, #fff 15%);
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #0275d8;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  box-shadow: none;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url("app/assets/images/select2-spinner.gif") no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 18px;
  margin: 3px 0 3px 5px;
  position: relative;
  line-height: 13px;
  color: #333;
  cursor: default;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #e4e4e4;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;
  font-size: 1px;
  outline: none;
  background: url("app/assets/images/select2.png") right top no-repeat;
}

.select2-container-multi .select2-search-choice-close {
  left: 3px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover, .select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}

.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: none;
}

/* disabled styles */
/* end multiselect */
.select2-result-selectable .select2-match, .select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input, .select2-search-choice-close {
    background-image: url("app/assets/images/select2x2.png") !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-container .select2-choice abbr, .select2-container .select2-choice .select2-arrow b {
    background-image: url("app/assets/images/select2x2.png") !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-search input {
    background-position: 100% -21px !important;
  }
}

.select2-chosen button {
  background-color: transparent !important;
  font-size: 14px;
}

.gridster {
  height: 100%;
}

.gridster .gridster-item {
  background-color: rgba(255, 255, 255, 0.76);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.gridster .gridster-item .gridster-header {
  cursor: move;
}

.gridster .gridster-item.gridster-item-moving {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.gridster .gridster-item .grid-heading {
  font-size: 1.2em;
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.gridster .gridster-item .grid-heading h3 {
  font-size: 1.2em;
  padding: 0;
  margin: 0;
  float: left;
}

.gridster .gridster-item .grid-heading .actions {
  margin-left: auto;
}

.gridster .gridster-item .grid-heading .actions a {
  padding: 0 5px;
}

.gridster .gridster-content {
  height: calc(100% - (47px * 2)) !important;
  padding: 0 !important;
}

.gridster .gridster-content .widget-content {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  overflow: hidden;
}

.gridster .gridster-content .widget-content .meter-widget.table-widget, .gridster .gridster-content .widget-content .meter-widget.graph-widget {
  width: 100%;
}

.gridster .DARK,
.gridster .LIGHT {
  height: 100%;
}

.gridster .DARK .card-header {
  background-color: #485563;
  border-bottom-color: #485563;
}

.gridster .DARK .card-footer {
  background-color: #29323c;
  color: rgba(255, 255, 255, 0.5) !important;
  border-top-color: #29323c;
}

.gridster .DARK .gridster-content {
  background: #485563;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #29323c, #485563);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gridster .DARK table.table tr th,
.gridster .DARK table.table tr td {
  color: #ECE9E6 !important;
}

.gridster .LIGHT .card-header {
  background-color: #ffffff;
  border-bottom-color: #ffffff;
  color: #292b2c !important;
}

.gridster .LIGHT .card-header a {
  color: #292b2c !important;
}

.gridster .LIGHT .card-footer {
  background-color: #ECE9E6;
  color: #292b2c !important;
  border-top-color: #ECE9E6;
}

.gridster .LIGHT .gridster-content {
  background: #ffffff;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #ECE9E6, #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ui-draggable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

.ui-draggable-dragging {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #292b2c;
  color: rgba(255, 255, 255, 0.76);
  clear: both;
  float: left;
  width: 150px;
}

.ui-droppable-hover {
  background-color: #5cb85c !important;
  color: rgba(255, 255, 255, 0.76);
}

.ui-droppable-hover a {
  color: rgba(255, 255, 255, 0.76);
}

.ui-notification {
  box-shadow: none;
  padding: 10px 20px;
  width: 480px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-notification h3 {
  font-size: 1.2em;
}

.ui-notification.warning {
  background-color: #f0ad4e;
}

.ui-notification:hover {
  opacity: 1 !important;
}

.ngdialog-alert .ngdialog-content {
  height: 600px !important;
  overflow: auto;
  border-radius: 0 !important;
  padding: 25px !important;
  background-color: rgba(255, 255, 255, 0.76) !important;
}

.ngdialog-alert .ngdialog-content h3 {
  color: #0275d8;
  margin: 10px 0 20px 0;
}

.ngdialog-alert .ngdialog-content p {
  font-size: 16px;
}

.ngdialog-content {
  border-radius: 0 !important;
  padding: 25px !important;
  background-color: rgba(255, 255, 255, 0.76) !important;
}

.ngdialog-content h3 {
  color: #0275d8;
  margin: 10px 0 20px 0;
}

.ngdialog-content p {
  font-size: 16px;
}

button.ngdialog-close:before {
  color: #d9534f !important;
}

.ngdialog-footer {
  text-align: right;
}

.custom_big_dialog .ngdialog-content {
  width: 800px !important;
}

.custom_big_dialog .preview {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 5px;
  height: 100px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: calc((100%/4) - 10px) !important;
  animation: all 500ms ease;
}

.custom_big_dialog .preview h4 {
  display: none;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
}

.custom_big_dialog .preview:hover {
  animation: all 500ms ease;
}

.custom_big_dialog .preview:hover h4 {
  display: inherit;
}

.custom_big_dialog .preview:hover img {
  display: none;
}

.navbar {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.navbar .navbar-brand .toggleSidebar {
  display: inline-block;
  color: rgba(255, 255, 255, 0.76) !important;
  margin-right: 10px;
  transition: all ease 500ms;
}

.navbar .navbar-brand .toggleSidebar.toggled {
  transform: rotate(90deg);
  transition: all ease 500ms;
}

.navbar .navbar-brand img {
  height: 35px;
}

.navbar .dropdown-menu.toggle-right {
  right: 0;
  left: auto;
  top: 45px;
  border: none;
  border-radius: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card {
  border-radius: 0;
}

.card .card-header {
  border-radius: 0;
}

.card .card-header .mb-0 {
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.card .card-header-alerts {
  border-radius: 0;
  border-bottom: none;
}

.card .card-header-alerts .mb-0 {
  margin-left: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.card .card-body {
  padding: 25px;
}

.card .card-body-alerts {
  padding: 5px;
  text-align: center;
}

.card .card-block {
  padding: 0;
}

.card .card-block .list-group {
  border: none;
}

.card .card-block .list-group .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card .card-block .list-group .list-group-item:first-of-type {
  border-top: none;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #6e6e6e;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid rgba(255, 255, 255, 0.76);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #0275d8;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
}

.custom-radio input:checked ~ .checkmark {
  background-color: #0275d8;
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}

.custom-radio .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  content: "";
  position: absolute;
  display: none;
}

.custom-radio:hover input ~ .checkmark {
  background-color: #6e6e6e;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-train-station, .odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-train-station .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-train-station .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-train-station .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-train-station .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-train-station .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-train-station .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-train-station .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-up .odometer-ribbon-inner {
  transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-down .odometer-ribbon-inner {
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: transform 2s;
  transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-train-station {
  font-family: "Economica", sans-serif;
  font-size: 3rem;
}

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-train-station .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  border-radius: 0.1em;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzExMTExMSIvPjxzdG9wIG9mZnNldD0iMzUlIiBzdG9wLWNvbG9yPSIjMTExMTExIi8+PHN0b3Agb2Zmc2V0PSI1NSUiIHN0b3AtY29sb3I9IiMzMzMzMzMiLz48c3RvcCBvZmZzZXQ9IjU1JSIgc3RvcC1jb2xvcj0iIzExMTExMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzExMTExMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: linear-gradient(to bottom, #111111 0%, #111111 35%, #333333 55%, #111111 55%, #111111 100%);
  background-color: #222;
  padding: 0 0.15em;
  color: #fff;
}

.odometer.odometer-auto-theme .odometer-digit + .odometer-digit, .odometer.odometer-theme-train-station .odometer-digit + .odometer-digit {
  margin-left: 0.1em;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-train-station .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.altimeter .info,
.thermometer .info,
.anglemeter .info,
.fuelmeter .info,
.odometer .info {
  margin-left: 15px;
}

.altimeter .info h1,
.thermometer .info h1,
.anglemeter .info h1,
.fuelmeter .info h1,
.odometer .info h1 {
  margin: 0;
  color: rgba(255, 255, 255, 0.76);
}

.altimeter .info h2,
.thermometer .info h2,
.anglemeter .info h2,
.fuelmeter .info h2,
.odometer .info h2 {
  margin: 0;
  color: rgba(255, 255, 255, 0.76);
  font-size: 1.5rem;
  font-weight: normal;
}

.altimeter .info h1,
.thermometer .info h1,
.anglemeter .info h1,
.fuelmeter .info h1,
.odometer .info h1 {
  font-size: 4rem;
  position: relative;
  display: block;
  height: 70px;
}

.altimeter .info h1 small,
.thermometer .info h1 small,
.anglemeter .info h1 small,
.fuelmeter .info h1 small,
.odometer .info h1 small {
  font-size: 1rem;
  vertical-align: top;
  display: inline-block;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.5);
}

.LIGHT .altimeter h2, .LIGHT .altimeter h1,
.LIGHT .thermometer h2,
.LIGHT .thermometer h1,
.LIGHT .anglemeter h2,
.LIGHT .anglemeter h1,
.LIGHT .fuelmeter h2,
.LIGHT .fuelmeter h1,
.LIGHT .odometer h2,
.LIGHT .odometer h1 {
  color: #29323c;
}

.LIGHT .altimeter h1 small,
.LIGHT .thermometer h1 small,
.LIGHT .anglemeter h1 small,
.LIGHT .fuelmeter h1 small,
.LIGHT .odometer h1 small {
  color: rgba(0, 0, 0, 0.5);
}

.LIGHT .anglemeter .meter .circle {
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.LIGHT .anglemeter .meter .circle .indicator {
  background-color: #29323c;
}

.LIGHT .fuelmeter .barrel {
  background: rgba(0, 0, 0, 0.5);
}

.LIGHT .fuelmeter .barrel .wave {
  background: rgba(0, 0, 0, 0.5);
}

.LIGHT .fuelmeter .barrel .droplet {
  background-image: url("app/assets/images/meters/droplet.png");
}

.LIGHT .altimeter .meter .altitude {
  background-color: rgba(0, 0, 0, 0.5);
}

.LIGHT .speedometer .meter {
  background-image: url("app/assets/images/meters/indicator_dark.png");
}

.LIGHT .speedometer .meter .slider {
  background-image: url("app/assets/images/meters/slider_dark.png");
}

.LIGHT .speedometer .meter .slider .info {
  color: #29323c;
}

.LIGHT .speedometer .meter .slider .info h1 small {
  color: rgba(0, 0, 0, 0.5);
}

/**
*  ODOMETER Styling
*/
.odometer {
  font-size: 3rem;
  color: #fff;
  position: relative;
}

.odometer .num {
  display: inline-block;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-right: 5px;
  position: relative;
}

.odometer .num:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

.odometer .unit {
  font-size: 1rem;
  position: absolute;
  top: 0;
  right: -25px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}

/**
*  ALTIMETER Styling
*/
.altimeter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

.altimeter .meter {
  width: 80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
}

.altimeter .meter .altitude {
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 1px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.altimeter .meter .altitude.active {
  background-color: #29ABE2;
}

.altimeter .meter .altitude.active.danger {
  background-color: #F22232;
}

.altimeter .meter .altitude.active.warning {
  background-color: #e67e22;
}

/*
*  THERMOMETER Styling
*/
.thermometer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

.thermometer .meter {
  width: 50px;
  display: -ms-flexbox;
  display: flex;
}

.thermometer .meter .bg {
  position: relative;
}

.thermometer .meter .bg .circle {
  position: absolute;
  bottom: 0;
  left: 10px;
  background-color: #F22232;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0 8px 4px rgba(242, 34, 50, 0.3), 0 0 8px rgba(242, 34, 50, 0.1);
}

.thermometer .meter .bg .bar {
  position: absolute;
  top: 0;
  bottom: 25px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 20px;
  display: -ms-flexbox;
  display: flex;
}

.thermometer .meter .bg .bar .indicator {
  background-color: #F22232;
  width: 100%;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

/**
* ANGLEMETER Styling
*/
.anglemeter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

.anglemeter .meter .circle {
  display: block;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.anglemeter .meter .circle .indicator {
  display: block;
  width: 50px;
  height: 3px;
  background-color: #fff;
  transform-origin: center right;
  margin-top: 50px;
  position: relative;
}

.anglemeter .meter .circle .indicator:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #F22232;
  position: absolute;
  top: -3.5px;
  left: -5px;
}

/**
* FUELMETER Styling
*/
.fuelmeter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

.fuelmeter .barrel {
  width: 75px;
  height: 100px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.fuelmeter .barrel .wave {
  opacity: .4;
  position: absolute;
  top: 3%;
  left: 50%;
  background: rgba(255, 255, 255, 0.5);
  width: 400px;
  height: 400px;
  margin-left: -200px;
  margin-top: 75px;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 3000ms infinite linear;
}

.fuelmeter .barrel .wave.three {
  animation: drift 5000ms infinite linear;
}

.fuelmeter .barrel .wave.two {
  animation: drift 7000ms infinite linear;
  opacity: .1;
  background: rgba(255, 255, 255, 0.5);
}

.fuelmeter .barrel .droplet {
  background-image: url("app/assets/images/meters/droplet.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 34px;
  z-index: 1;
  margin: auto;
  margin-top: 20px;
}

/**
* SPEEDOMETER Styling
 */
.speedometer .meter {
  width: 400px;
  height: 400px;
  background-image: url("app/assets/images/meters/indicator.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.speedometer .meter .slider {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url("app/assets/images/meters/slider.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.speedometer .meter .slider .indicator {
  display: block;
  width: 3px;
  height: 100px;
  transform-origin: bottom center;
  margin: 106px auto auto;
  transform: rotate(-122deg);
  /* 15 --> 10 km/h */
  position: relative;
}

.speedometer .meter .slider .indicator:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #F22232;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -4.5px;
  box-shadow: 0 0 20px rgba(242, 34, 50, 0.5), 0 0 16px rgba(242, 34, 50, 0.2), 0 0 8px rgba(242, 34, 50, 0.1);
}

.speedometer .meter .slider .info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  color: #fff;
}

.speedometer .meter .slider .info h1 {
  font-size: 3rem;
}

.speedometer .meter .slider .info h1 small {
  font-size: 1rem;
  display: block;
  color: rgba(255, 255, 255, 0.5);
}

/**
* LED Styling
 */
.led .meter .circle {
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.led .meter.on .circle {
  background-color: #24BDB7;
  box-shadow: 0 0 32px rgba(36, 189, 183, 0.5), 0 0 16px rgba(36, 189, 183, 0.3), 0 0 8px rgba(36, 189, 183, 0.2);
}

.led .meter.on h2 {
  color: #24BDB7;
  text-align: center;
}

.led .meter.off .circle {
  background-color: #F22232;
  box-shadow: 0 0 32px rgba(242, 34, 50, 0.5), 0 0 16px rgba(242, 34, 50, 0.3), 0 0 8px rgba(242, 34, 50, 0.2);
}

.led .meter.off h2 {
  color: #F22232;
  text-align: center;
}

.string-widget h1,
.number-widget h1 {
  color: #ECE9E6;
}

.string-widget h1 small,
.number-widget h1 small {
  font-size: 1rem;
  vertical-align: top;
  display: inline-block;
  margin-top: 5px;
}

.string-widget.LIGHT h1,
.number-widget.LIGHT h1 {
  color: #29323c;
}

.table-widget .table tfoot .info {
  display: none;
}

.table-widget .table tfoot ul.pagination li.active a {
  background-color: #0275d8;
}

.table-widget .table tfoot ul.pagination li a {
  background-color: transparent;
}

.table-widget .table tfoot ul.pagination li a.disabled {
  color: #6e6e6e;
}

.table-widget .table tfoot ul.pagination li .custom-select {
  border-radius: 0;
}

.udatetime {
  position: relative;
  padding: 0 !important;
}

.udatetime table.cal {
  position: absolute;
  z-index: 9999;
}

.udatetime td.day button {
  cursor: pointer;
}

.udatetime td.day button.btn-default {
  background-color: transparent;
}

.udatetime table.table.cal {
  display: none !important;
  transition: all ease 2s;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  width: auto !important;
}

.udatetime table.cal.shown {
  display: table !important;
  transition: all ease 2s;
}

.udatetime .input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.udatetime .input-group .form-control:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-addon:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn, .udatetime .input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-btn:not(:last-child) > .btn, .udatetime .input-group-btn:not(:last-child) > .btn-group > .btn, .udatetime .input-group-btn:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group .form-control, .udatetime .input-group-addon, .udatetime .input-group-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.udatetime .input-group .form-control {
  position: relative;
  z-index: 2;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.udatetime .form-control {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin: 0 !important;
}

.udatetime .form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.udatetime .input-group .form-control:not(:first-child), .udatetime .input-group-addon:not(:first-child) {
  margin-left: -17px;
  width: 36px;
  height: 36px;
  padding: 0;
  text-align: center;
  z-index: 2;
}

.udatetime .input-group-btn:not(:first-child) > .btn, .udatetime .input-group-btn:not(:first-child) > .btn-group > .btn, .udatetime .input-group-btn:not(:first-child) > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.udatetime .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn, .udatetime .input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.udatetime .input-group-addon {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #fafafa;
  text-align: center;
  background-color: #0275d8;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  white-space: nowrap;
  vertical-align: middle;
}

.udatetime .input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.udatetime .input-group .form-control, .udatetime .input-group-addon, .udatetime .input-group-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.udatetime fieldset.form-group {
  margin-bottom: 1rem;
}

.udatetime .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.udatetime .table thead th:first-of-type {
  width: auto !important;
}

.udatetime .table thead th:last-of-type {
  width: auto !important;
}

.udatetime .table thead th:last-child {
  min-width: auto;
}

.udatetime .table tr:hover {
  background-color: transparent;
}

.udatetime .table tr td {
  border: none !important;
  text-align: center;
  width: auto !important;
}

.udatetime .table tr td .btn {
  height: 30px;
  width: 30px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center !important;
  display: inline-block !important;
  margin: auto;
  padding: 0 !important;
  line-height: inherit;
  border-radius: 50% !important;
}

.udatetime .table tr td .btn.month {
  height: 50px;
  width: 50px;
  text-transform: capitalize;
}

.udatetime .table tr td .btn.btn-default {
  border: none;
}

.udatetime .table tr td .btn.btn-block {
  width: 100%;
  padding: 10px;
}

.udatetime .table tfoot td .btn {
  border-radius: 0;
  height: auto;
  width: auto;
}

.udatetime .table tfoot td .btn.btn-info {
  background-color: transparent;
  color: #025aa5 !important;
  border: none;
  font-size: 1.2em;
}

.udatetime table {
  border-collapse: collapse;
  background-color: transparent;
}

.udatetime .table thead th {
  vertical-align: bottom;
  text-transform: capitalize;
  border: none;
}

.udatetime .table thead th button {
  background-color: transparent;
  color: #eee;
  font-size: 1.5em;
}

.udatetime thead tr:first-of-type th {
  background-color: #025aa5;
}

.udatetime thead tr:last-of-type {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.udatetime .thead-inverse th {
  color: #fff;
  background-color: #0275d8;
}

.udatetime .table td, .udatetime .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #eee;
}

.udatetime .text-center {
  text-align: center !important;
}

.udatetime .btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .5rem 1rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: all .2s ease-in-out;
}

.udatetime .btn-group-sm > .btn, .udatetime .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.udatetime button, .udatetime input, .udatetime select, .udatetime textarea {
  line-height: inherit;
}

.udatetime [role=button], .udatetime a, .udatetime area, .udatetime button, .udatetime input, .udatetime label, .udatetime select, .udatetime summary, .udatetime textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.udatetime [type=reset], .udatetime [type=submit], .udatetime button, .udatetime html [type=button] {
  -webkit-appearance: button;
}

.udatetime button, .udatetime input {
  overflow: visible;
}

.udatetime button, .udatetime select {
  text-transform: none;
}

.udatetime .btn-block {
  display: block;
  width: 100%;
}

.udatetime .btn-info {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.udatetime .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.udatetime .btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.udatetime .h3, .udatetime h3 {
  font-size: 1.75rem;
}

.udatetime .h1, .udatetime .h2, .udatetime .h3, .udatetime .h4, .udatetime .h5, .udatetime .h6, .udatetime h1, .udatetime h2, .udatetime h3, .udatetime h4, .udatetime h5, .udatetime h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.udatetime input:-moz-read-only {
  background-color: #ccc;
  cursor: not-allowed;
}

.udatetime input:read-only {
  background-color: #ccc;
  cursor: not-allowed;
}

.udatetime input:-moz-read-only + span.input-group-addon {
  background-color: #ccc;
}

.udatetime input:read-only + span.input-group-addon {
  background-color: #ccc;
}

.udatetime input.form-control {
  width: auto !important;
  max-width: 160px;
  margin-left: 10px;
}

.udatetime input.time-input {
  font-size: 1.8em;
  border: none;
  width: 80px !important;
  text-align: center;
}

.udatetime input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  vertical-align: middle;
}

.switch input {
  display: none;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch-text {
  line-height: 34px;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

canvas#viewport {
  border: 1px solid white;
  width: 900px;
}

.login-page {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.login-page img.logo {
  width: 50%;
  margin-bottom: 10px;
  margin-top: -10px;
}

.login-page .centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: rgba(255, 255, 255, 0.76);
  padding: 0;
  border-radius: 5px;
}

.login-page .shadow {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 1;
}

.login-page .background {
  position: relative;
  height: 100vh;
}

.login-page .background:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: url("app/assets/images/background.jpg") no-repeat center;
  background-size: cover;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.login-page .form-container {
  width: 100%;
  padding: 10px 25px;
  background-color: transparent;
  border: none;
}

.login-page .form-container .languages {
  display: inline-block;
  margin-right: auto;
}

.login-page .lang {
  opacity: .5;
}

.login-page .lang.active {
  opacity: 1;
}

.login-page .footer {
  background-color: rgba(255, 255, 255, 0.76);
  padding: 25px;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  z-index: 2;
}

.login-page .footer .logos {
  padding-left: 25px;
}

.login-page .footer .logos .logo {
  height: 50px;
  width: auto;
  margin: 0 10px;
}

.login-page .footer .socials {
  padding-right: 25px;
}

.login-page .footer .socials li {
  padding: 0 10px;
}

.login-page .footer .socials li a img {
  height: 30px;
  width: auto;
}

/* Shared */
.loginBtn {
  position: relative;
  color: rgba(255, 255, 255, 0.76);
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.loginBtn.facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  text-shadow: 0 -1px 0 #3B55A0;
}

.loginBtn.facebook:before {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-right: #3B55A0 1px solid;
  content: "\f09a";
  font-family: FontAwesome,sans-serif;
  font-size: 1.2rem;
}

.loginBtn.facebook:hover, .loginBtn.facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}

.loginBtn.google {
  background: #DD4B39;
}

.loginBtn.google:before {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-right: #BB3F30 1px solid;
  content: "\f0d5";
  font-family: FontAwesome,sans-serif;
  font-size: 1.2rem;
}

.loginBtn.google:hover, .loginBtn.google:focus {
  background: #E74B37;
}

.loginBtn.signup {
  background: #485563;
}

.loginBtn.signup:hover, .loginBtn.signup:focus {
  background: #14191e;
}

.sidebar {
  background-color: rgba(255, 255, 255, 0.76);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 999;
  /*  .collapse{
    overflow-x: hidden;
    overflow-y: auto;
    &.no-scroll{
      overflow:visible;
    }
  }*/
}

.sidebar li.list-group-item.disabled {
  opacity: .7;
}

.sidebar li.list-group-item.disabled.active {
  opacity: 1;
}

#addDashbForm textarea {
  resize: none;
}

.widget .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.alerts {
  overflow: hidden;
  position: fixed;
  height: 100%;
  top: 54px;
  width: 50%;
  right: -50%;
  bottom: 0;
  z-index: 999;
  background-color: white;
  transition: right 500ms ease;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.alerts.shown {
  right: 0 !important;
  transition: right 500ms ease;
}

#alert-container {
  max-height: 100%;
  overflow-y: hidden;
  padding: 0em 0em 5em;
}

#accordionAlert {
  max-height: 100%;
  overflow-y: auto;
}

#alertList {
  max-height: 100%;
  overflow-y: hidden;
}

.sidebar .dashboard-item {
  position: relative;
  overflow-y: hidden;
}

.sidebar .dashboard-item .popup-actions {
  position: absolute;
  background-color: white;
  padding: 10px 20px;
  display: none;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.15);
}

.sidebar .dashboard-item.open .popup-actions {
  display: block;
  position: fixed;
  z-index: 50000;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

.dashboards .card-block {
  max-height: 300px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.home-heading .card-body {
  padding: 20px  !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.home-heading .card-body h3 {
  margin: 0;
}

/*
.card.dashboards{
 ul.list-group{
   /!*display: block !important;
   max-height: 180px;
   overflow-x: visible;
   overflow-y: auto;
   .popup-actions{
      z-index: 9999;
   }*!/
 }
}
*/
/**
*
* Leaflet Context menu
*
 */
.leaflet-contextmenu {
  display: none;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 4px 0;
  background-color: rgba(255, 255, 255, 0.76);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item {
  display: block;
  color: #222;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  padding: 0 12px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  cursor: default;
  outline: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item.over {
  background-color: #f4f4f4;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled {
  opacity: 0.5;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled.over {
  background-color: inherit;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.leaflet-contextmenu-icon {
  margin: 2px 8px 0 0;
  width: 16px;
  height: 16px;
  float: left;
  border: 0;
}

.leaflet-contextmenu-separator {
  border-bottom: 1px solid #ccc;
  margin: 5px 0;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polyline,
.leaflet-draw-toolbar .leaflet-draw-draw-polygon,
.leaflet-draw-toolbar .leaflet-draw-draw-rectangle,
.leaflet-draw-toolbar .leaflet-draw-draw-circle,
.leaflet-draw-toolbar .leaflet-draw-draw-marker,
.leaflet-draw-toolbar .leaflet-draw-edit-edit,
.leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-image: linear-gradient(transparent, transparent), url("app/assets/images/leaflet_draw_icons.svg");
  background-repeat: no-repeat;
}

.leaflet-retina .leaflet-draw-toolbar .leaflet-draw-draw-polyline,
.leaflet-retina .leaflet-draw-toolbar .leaflet-draw-draw-polygon,
.leaflet-retina .leaflet-draw-toolbar .leaflet-draw-draw-rectangle,
.leaflet-retina .leaflet-draw-toolbar .leaflet-draw-draw-circle,
.leaflet-retina .leaflet-draw-toolbar .leaflet-draw-draw-marker,
.leaflet-retina .leaflet-draw-toolbar .leaflet-draw-edit-edit,
.leaflet-retina .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-image: linear-gradient(transparent, transparent), url("app/assets/images/leaflet_draw_icons.svg");
  background-repeat: no-repeat;
}

/**
*
* Leaflet Control menu
*
 */
.leaflet-control-layers {
  visibility: hidden !important;
}

.leaflet-bar {
  border-radius: 25px !important;
  background-color: #0079e3 !important;
}

.leaflet-control-zoom-fullscreen {
  border-radius: 50% !important;
  background-color: transparent !important;
  color: #ffffff !important;
}

.leaflet-control-zoom {
  overflow: hidden;
}

.leaflet-control-zoom a {
  background-color: #0079e3;
  color: rgba(255, 255, 255, 0.76);
  border-bottom: none;
}

.leaflet-control-zoom a:hover {
  background-color: #005ee1;
  color: rgba(255, 255, 255, 0.76);
  border-bottom: none;
}

.leaflet-draw-toolbar.leaflet-bar a,
.leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top a {
  background-color: transparent;
  border: none;
}

/**
*
* Leaflet popup Styling
*
 */
.leaflet-popup {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.leaflet-popup a.leaflet-popup-close-button {
  display: block;
  width: 20px;
  height: 20px;
  background: url("app/assets/images/close.png") no-repeat;
  background-size: 100%;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  text-indent: -9999px;
}

.leaflet-popup .leaflet-popup-content-wrapper {
  padding: 3px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2) !important;
}

.leaflet-popup .popup-heading {
  font-size: 1.5em;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  font-family: "Helvetica Neue", FontAwesome, Helvetica, Arial, sans-serif;
  color: #0275d7;
}

.leaflet-popup .popup-details {
  font-size: 1.2em;
  color: #0275d8;
}

.leaflet-popup .popup-details .fa {
  color: #0275d7;
}

.leaflet-popup .popup-details b {
  font-weight: bold;
  font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
  color: #0275d7;
}

/**
*
* Leaflet zoomSlider control
*
*/
.leaflet-control-zoomslider {
  border-radius: 25px;
  background-color: #0079e3;
}

a.leaflet-control-zoomslider-out {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.76);
  border: 2px solid #005ee1 !important;
  color: #0079e3;
}

a.leaflet-control-zoomslider-out:hover {
  color: #005ee1;
  border-bottom: 2px solid #005ee1;
}

a.leaflet-control-zoomslider-out:after {
  content: '-';
  font-size: 1.8em;
  font-weight: bold;
  line-height: 25px;
  font-family: Helvetica, Arial, sans-serif;
}

a.leaflet-control-zoomslider-in {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.76);
  color: #0079e3;
  border: 2px solid #005ee1;
}

a.leaflet-control-zoomslider-in:hover {
  color: #0275d7;
  border-bottom: 2px solid #005ee1;
}

a.leaflet-control-zoomslider-in:after {
  content: "+";
  font-size: 1.8em;
  font-weight: bold;
  line-height: 21px;
  font-family: Helvetica, Arial, sans-serif;
}

.leaflet-control-zoomslider-wrap {
  padding: 0;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.leaflet-control-zoomslider-body {
  height: 158px !important;
  position: relative;
}

.leaflet-control-zoomslider-body:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  content: "";
  display: block;
  height: 80%;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.76);
  border-radius: 2px;
  margin: auto;
  z-index: -1;
}

.leaflet-control-zoomslider-body .leaflet-control-zoomslider-knob {
  display: block;
  background-color: rgba(255, 255, 255, 0.76);
  border: 2px solid #005ee1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  transform-origin: 10px 10px;
}

a.leaflet-control-zoomhome-in {
  display: none !important;
}

a.leaflet-control-zoomhome-out {
  display: none !important;
}

.leaflet-control-zoomhome {
  margin-top: -30px !important;
  padding-top: 30px;
  padding-bottom: 5px;
  background-color: rgba(2, 117, 215, 0.5);
  z-index: -1;
  border-radius: 0 0 25px 25px;
}

.leaflet-control-zoomhome-home {
  background-color: transparent !important;
  color: white !important;
  border-bottom: none !important;
}

.leaflet-control-zoomhome-home i {
  font-size: 1.2em !important;
}

.leaflet-control-easyPrint {
  background-color: rgba(2, 117, 215, 0.5);
  border-radius: 25px;
  overflow: hidden;
}

.leaflet-control-easyPrint a.leaflet-control-easyPrint-button {
  background-color: transparent !important;
  background: url("app/assets/images/printer.svg") no-repeat;
  background-size: 60%;
  background-position: center;
}

.leaflet-control-zoomslider-disabled {
  opacity: 1;
  pointer-events: none;
}

/*  page map bts */
.myMAp {
  height: 100%;
  min-height: calc(100% - (74px + 59px));
  width: 100%;
}

#maprealtime {
  height: 100%;
  width: 100%;
}

/* map devices */
#mapDevicerealtime {
  height: 100%;
  width: 100%;
}

ul.base-layers {
  position: absolute;
  top: 0;
  right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  z-index: 999;
  list-style: none;
}

ul.base-layers li {
  padding: 5px;
}

ul.base-layers li.active a img {
  border: 2px solid #0079e3;
}

ul.base-layers li img {
  width: 40px;
  border-radius: 50%;
  height: auto;
  vertical-align: middle;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border: 2px solid #eee;
}

ul.base-layers .list-group-item {
  background-color: transparent;
  border: none;
}

.leaflet-toolbar-0 {
  border: none;
}

.leaflet-toolbar-0 > li > .leaflet-toolbar-icon {
  background-color: #0079e3;
}

.leaflet-toolbar-0 > li > .leaflet-toolbar-icon:hover {
  background-color: #005ee1;
}

.leaflet-popup-toolbar a {
  color: #eee;
}

.leaflet-toolbar-tip {
  background-color: #0079e3;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.leaflet-popup {
  font-size: 12px;
}

.leaflet-measure-resultpopup {
  font-size: 14px;
}

.leaflet-measure-resultpopup h3 {
  font-weight: bold;
}

.leaflet-measure-resultpopup a {
  color: #0079e3;
}

.leaflet-measure-resultpopup a.deletemarkup {
  color: #d9534f;
}

.milestone-marker .marker-circle {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #0079e3;
  border-radius: 50%;
}

.milestone-marker .marker-circle.start {
  background-color: #5cb85c;
}

.milestone-marker .values {
  margin-left: -40px;
  text-align: center;
  text-shadow: white 2px 0px 0px, white 1.75517px 0.95885px 0px, white 1.0806px 1.68294px 0px, white 0.14147px 1.99499px 0px, white -0.83229px 1.81859px 0px, white -1.60229px 1.19694px 0px, white -1.97998px 0.28224px 0px, white -1.87291px -0.70157px 0px, white -1.30729px -1.5136px 0px, white -0.42159px -1.95506px 0px, white 0.56732px -1.91785px 0px, white 1.41734px -1.41108px 0px, white 1.92034px -0.55883px 0px;
  color: #0079e3;
}

.milestone-marker .values .distance {
  display: block;
  width: 100px;
  font-weight: bold;
}

/*  page statics */
.inline {
  display: -ms-flexbox;
  display: flex;
}

.inline .buttons {
  display: -ms-flexbox;
  display: flex;
}

.inline .datetime form {
  position: relative;
}

.inline .datetime form fieldset {
  border: none;
  display: inline-block;
}

.inline .datetime form fieldset label {
  display: block;
  margin-bottom: 10px;
  color: #0275d7;
}

.inline .datetime form fieldset .openCal {
  background-color: #0275d7;
  color: rgba(255, 255, 255, 0.76);
  border-radius: 0 5px 5px 0 !important;
  height: 35px;
  margin-left: -5px;
}

.inline .datetime form fieldset .datetime-picker-dropdown {
  left: 0;
  display: block;
  padding: 10px;
  position: absolute;
  background: rgba(255, 255, 255, 0.76);
  border-radius: 3px;
  list-style: none;
}

.inline .datetime form fieldset .datetime-picker-dropdown button {
  padding: 10px !important;
}

.inline .datetime form fieldset .datetime-picker-dropdown button.btn-default {
  background-color: rgba(255, 255, 255, 0.76);
}

.inline .datetime form fieldset .datetime-picker-dropdown button.btn-default:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.inline .datetime form fieldset .datetime-picker-dropdown button.btn-primary {
  background-color: #005ee1;
}

.inline .datetime form fieldset .datetime-picker-dropdown > li.date-picker-menu div > table .btn-default {
  border: 0;
}

.inline .datetime form fieldset button.btn {
  padding: 10px;
  border-radius: 2px;
}

.inline .datetime form fieldset button.btn.btn-primary {
  background-color: #005ee1;
  color: rgba(255, 255, 255, 0.76);
}

/*  page statics */
.statHeader {
  display: -ms-flexbox;
  display: flex;
  height: 34px;
}

.mapHeader {
  display: -ms-flexbox;
  display: flex;
  height: 34px;
}

/*.row{
  body {
    font-family: 'Open Sans', sans-serif;
    padding: 2% 5%;
    background: #323232;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 0 0 10px;
    color: #f0f0f0;
    font-weight: 300;
    text-align: center;
  }

  .btn { margin-left: 4px; }

}*/
.row .col-md-6 .card .card-header {
  padding-bottom: 2px;
  padding-top: 2px;
  height: 6px;
  background-color: rgba(190, 190, 192, 0.52);
}

.row .col-md-6 .card .card-block {
  min-height: 250px !important;
  min-width: 346px !important;
}

.row .col-md-6 .card .card-footer {
  padding-bottom: 2px;
  padding-top: 2px;
  height: 6px;
  background-color: rgba(190, 190, 192, 0.52);
}

/*  page statics */
#chartSignal {
  width: auto;
  height: auto;
  max-width: inherit;
  margin-left: 0px;
  padding: 0px;
  min-height: 250px !important;
  min-width: 346px !important;
}

#msgUpChart {
  width: auto;
  height: auto;
  max-width: inherit;
  margin-left: 0px;
  padding: 0px;
  min-height: 250px !important;
  min-width: 346px !important;
}

#msgDownChart {
  width: auto;
  height: auto;
  max-width: inherit;
  margin-left: 0px;
  padding: 0px;
  min-height: 250px !important;
  min-width: 346px !important;
}

#devNumberChart {
  width: auto;
  height: auto;
  max-width: inherit;
  margin-left: 0px;
  padding: 0px;
  min-height: 250px !important;
  min-width: 346px !important;
}

#bestRssiChart {
  width: auto;
  height: auto;
  max-width: inherit;
  margin-left: 0px;
  padding: 0px;
  min-height: 250px !important;
  min-width: 346px !important;
}

#devOneBtsChart {
  width: auto;
  height: auto;
  max-width: inherit;
  margin-left: 0px;
  padding: 0px;
  min-height: 250px !important;
  min-width: 346px !important;
}

#avgRedondancyChart {
  width: auto;
  height: auto;
  max-width: inherit;
  margin-left: 0px;
  padding: 0px;
  min-height: 250px !important;
  min-width: 346px !important;
  /*  min-width: 500px;
  max-width: fit-content;
  height: 200px;
  max-height: 400px;
  margin-left: 0px;
  padding: 0px;*/
}

/*
.admin-heading{
  .btn{
    height: 40px;
  }
}*/
/*
.fullpage-device{
  @include fullheight(54px);

  .card{
    height: 50%;
    .card-body{
      height: 100%;
       .table{

        td,th{
          white-space: nowrap
        }
      }

    }
    .panel{
      min-width: fit-content;
      max-width: fit-content;
      display: flex;
      background-color: $white;
      border-radius: 5px;
      box-shadow: 2px 3px 4px rgba(0, 72, 228, 0.35);
      margin:  10px 10px;
      ul{
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        li{
          margin: 0 2px;
          &.label{
            line-height: 34px;
          }
        }
        &.datetime{
          padding: 10px 0;
          position: relative;
          .datetime-picker-dropdown {
            color: $grey;
            left: 0;
            display: block;
            padding: 10px;
            position: absolute;
            background: $white;
            border-radius: 3px;
            list-style: none;
            td, th{
              button{
                color: $grey;
                margin: 0;
                padding: 2px;
              }
            }
            button {
              padding: 10px !important;
              border-radius: 5px;
              &.btn-default {
                background-color: $white;
                &:hover {
                  background-color: rgba(0, 0, 0, .1);
                }
              }
              &.btn-primary {
                background-color: $primary;
              }
            }

            button.btn {
              padding: 10px 25px;
              margin: 1px;
              &.btn-primary {
                background-color: $btn_primary_bg_color;
                color: $btn_primary_text_color;
              }
            }
          }

          li{
            padding: 10px;
            color: $grey;

            input{
              border: 1px solid #ccc;
              padding: 10px;
              border-radius: 5px 0 0 5px;
              width: 140px;
              height: 37px;
            }
            button{
              background-color: $secondary;
              color: $white;
              padding: 10px;
              border-radius: 0 5px 5px 0;
              margin-left: -5px;
              height: 37px;
            }
          }
        }
        li{
          a{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px 10px 5px 10px;
            margin: 0 10px;
            color: $grey;
            span.icon {
              width: 40px;
              height: 40px;
              display: block;
              background-color: $grey;
              border-radius: 50%;
              margin-bottom: 5px;
              i {
                display: block;
                width: 24px;
                height: 28px;
                margin: 5px auto auto;
                &.calendar {
                  background: url($img_folder + "/device/calendar.png") no-repeat;
                }
                &.day {
                  background: url($img_folder + "/device/calendar-day.png") no-repeat;
                }
                &.week {
                  background: url($img_folder + "/device/calendar-week.png") no-repeat;
                }
                &.month {
                  background: url($img_folder + "/device/calendar-month.png") no-repeat;
                }
              }
            }
          }
          &.active{
            a{
              color: $secondary;
              border-bottom: 5px solid $secondary;
              span.icon{
                background-color: $secondary;
              }
            }
          }
        }
      }
    }


    .module-content{
      text-align: center;
      flex-direction: column;
    }
    .module-content{
      height: 100%;
      display: flex;
      flex: 1;
      border-radius: 5px;

      .chart{
        height: 300px;
        display:flex ;
        flex: 1;
      }
    }
    .module-content{
      padding: 0 0 0 5px;
      .data{

        span{
          padding: 10px;
        }
      }
    }
    .module-content{
      padding: 15px;
      min-height: inherit;
      max-height: fit-content;
      .icon{
        img{
          height: 50px;
          margin: 11px;
        }
      }
      .data{
        display: flex;
        align-items: center;
        justify-content: center;

        h3{
          padding: 0;
          margin: 5px 25px;
          font-size: 1em;
          &.text-drive{
            color: $success;
          }
          &.text-stop{
            color: $danger;
          }
          &.text-idle{
            color: $warning;
          }
          &.text-disabled{
            color: $grey;
          }
        }
      }
    }
    .module-content{
      background-color: $white;
      box-shadow: 0 2px 4px rgba(0,0,0,.1);
      border-radius: 5px;
      padding: 10px;
      display: flex;
      width: 100%;
      // min-height: 145px;
      min-height:fit-content;
      .data{
        //  display: flex;
        align-items: center;
        justify-content: center;
        ol{
          margin: 0;
          li{
            font-size: 16px;
          }
        }


        .counter{
          i.circle{
            display: inline-block;
            margin: 5px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          &.disabled{
            i.circle{
              background-color: $grey;
            }
          }
          &.idle{
            i.circle{
              background-color: $warning;
            }
          }
          &.stop{
            i.circle{
              background-color: $danger;
            }
          }
          &.drive{
            i.circle{
              background-color: $success;
            }
          }
        }
      }
      &.multi{
        flex-direction: column;
        .line{
          display: flex;
          flex-direction: row;
        }
      }
      .icon{
        max-height: fit-content;
        img{
          margin: 17px 17px 0 0;
        }
      }
      span.blue{
        color: $secondary;
        font-weight: bold;
        margin-left: 5px;
      }
      h2, p{
        margin: 10px 0;
      }
      p{
        font-size: 16px;
      }
    }


    .material-switch > input[type="checkbox"] {
      display: none;
    }

    .material-switch > label {
      cursor: pointer;
      height: 0px;
      position: relative;
      width: 40px;
    }

    .material-switch > label::before {
      background: rgb(0, 0, 0);
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      content: '';
      height: 16px;
      margin-top: -8px;
      position:absolute;
      opacity: 0.3;
      transition: all 0.4s ease-in-out;
      width: 40px;
    }
    .material-switch > label::after {
      background: rgb(255, 255, 255);
      border-radius: 16px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
      content: '';
      height: 24px;
      left: -4px;
      margin-top: -8px;
      position: absolute;
      top: -4px;
      transition: all 0.3s ease-in-out;
      width: 24px;
    }
    .material-switch > input[type="checkbox"]:checked + label::before {
      background: inherit;
      opacity: 0.5;
    }
    .material-switch > input[type="checkbox"]:checked + label::after {
      background: inherit;
      left: 20px;
    }





    /!*
    .col-3, .col-sm-3,  .col-md-3,  .col-lg-3,  .col-xl-3 {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 0px;
    }.col-9, .col-sm-9,  .col-md-9,  .col-lg-9,  .col-xl-9{
       position: relative;
       width: 100%;
       min-height: 1px;
       padding-right: 15px;
       padding-left: 0px;
     }
    *!/




  }

}
/!*#containerChart {
  min-width: 490px;
  max-width: fit-content;
  height: 400px;
  max-height: 600px;
  margin-left: -24px;
  padding: 0px;
}*!/
#containerMap {
  width: 580px;
  max-width: fit-content;
  height: 400px;
  max-height: 600px;
  margin-left: -20px;
  padding: 0px;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  margin-top: -100px;
}*/
/* Large desktops and laptops */
@media (min-width: 1200px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

@media (max-width: 1250px) {
  .statHeader {
    display: inline-block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (max-width: 820px) {
  .mapHeader {
    display: inline-block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .navbar .navbar-brand .toggleSidebar {
    display: inline-block;
  }
  .login-page .background {
    display: none;
  }
  .sidebar {
    position: fixed;
    width: 80%;
    left: -100%;
    top: 54px;
    bottom: 0;
    transition: all ease 500ms;
  }
  .sidebar.shown {
    left: 0;
    transition: all ease 500ms;
  }
}

@media (min-width: 402px) and (max-width: 1052px) {
  .card-block .row {
    display: inline;
  }
  .card-block .row .col-md-6 {
    max-width: 100% !important;
  }
}

/* Portrait phones and smaller */
